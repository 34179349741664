import Script from "next/script";

// Used by Next.js Image component.
// Needed to support old versions of Chrome, and Safari.
import matchAll from "string.prototype.matchall";

// Used by `use-resize-observer`.
// `ResizeObserver` may not be available, and will require a polyfill.
import { ResizeObserver } from "@juggle/resize-observer";

import * as Sentry from "@sentry/nextjs";

import { useRouter } from "next/router";
import Head from "next/head";
import { ApolloProvider } from "@apollo/client";
import { useEffect } from "react";

import FlagProvider from "lib/unleash";

import firebase from "lib/firebase/client";
import { FirebaseAuthProvider } from "lib/firebase/hooks";
import { createClient, cache, persistor } from "lib/apollo/client";
import { loadErrorMessages } from "@apollo/client/dev";
import { CartProvider } from "lib/cart";
import "./styles.css";

import BlankLayout from "layouts/BlankLayout";

import "mapbox-gl/dist/mapbox-gl.css";
import "i18n";
import "styles/global.css";

import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { A2HSProvider } from "lib/hooks/useA2HS";
loadErrorMessages();
matchAll.shim();
if (typeof window !== "undefined" && !window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver;
}

const client = createClient(cache);

firebase.auth().onAuthStateChanged((user) => {
    if (user) {
        Sentry.setUser({ id: user.uid, email: user.email });
    } else {
        Sentry.configureScope((scope) => scope.setUser(null));
        client.resetStore();
    }
});

const EnjinPelanggan = ({ Component, pageProps, err }) => {
    const router = useRouter();
    const Layout = Component.Layout || BlankLayout;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [router.pathname]);

    useEffect(() => {
        const init = async () => {
            try {
                persistor && persistor.restore();
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        };

        if (typeof window !== "undefined") {
            // eslint-disable-next-line no-console
            init().catch(console.error);
        }
    }, []);

    return (
        <FirebaseAuthProvider app={firebase}>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, viewport-fit=cover"
                />
                <meta
                    key="enjin-version"
                    property="enjin-version"
                    content={`enjin-pelanggan/${
                        process.env.NEXT_PUBLIC_ENJIN_VERSION || "unknown"
                    }`}
                />
                {process.env.DISABLE_INDEXING ? (
                    <meta name="robots" content="noindex, nofollow" />
                ) : null}
            </Head>

            <Script
                src="https://static.cloudflareinsights.com/beacon.min.js"
                data-cf-beacon='{"token": "c329a1da4464478caf12e270e41c70b0"}'
            />
            <Script src="https://checkout.razorpay.com/v1/checkout.js" />

            <ApolloProvider client={client}>
                <FlagProvider>
                    <CartProvider>
                        <A2HSProvider>
                            <Layout>
                                {/* `err` is a workaround for https://github.com/vercel/next.js/issues/8592 */}
                                <Component {...pageProps} err={err} />
                                <ToastContainer
                                    hideProgressBar
                                    position="top-center"
                                    theme="dark"
                                    transition={Slide}
                                />
                            </Layout>
                        </A2HSProvider>
                    </CartProvider>
                </FlagProvider>
            </ApolloProvider>
        </FirebaseAuthProvider>
    );
};

export default EnjinPelanggan;
