import {
    FlagProvider,
    UnleashClient,
    InMemoryStorageProvider,
    IConfig,
} from "@unleash/proxy-client-react";
import { ReactNode, useEffect } from "react";
import { useAuth } from "lib/firebase/hooks";

const isUnleashEnabled =
    typeof window !== "undefined" &&
    process.env.NEXT_PUBLIC_UNLEASH_URL &&
    process.env.NEXT_PUBLIC_UNLEASH_API_KEY;
const unleashConfig: IConfig = {
    url: process.env.NEXT_PUBLIC_UNLEASH_URL ?? "",
    clientKey: process.env.NEXT_PUBLIC_UNLEASH_API_KEY ?? "",
    refreshInterval: 300,
    appName: "pelanggan",
    environment: process.env.NODE_ENV,
};

if (!isUnleashEnabled) {
    unleashConfig.url = "https://feature-proxy-disabled.courtsite.dev/proxy";
    unleashConfig.clientKey = "disabled";
    // Needed to avoid having errors during server side
    unleashConfig.fetch = () => {};
    unleashConfig.storageProvider = new InMemoryStorageProvider();
}

const unleashClient = new UnleashClient(unleashConfig);

type ProviderProps = { children: ReactNode };
const Provider = ({ children }: ProviderProps): JSX.Element => {
    const { user, isInitialised } = useAuth();

    useEffect(() => {
        if (!isUnleashEnabled || !isInitialised) return;
        unleashClient.start();
        return () => {
            unleashClient.stop();
        };
    }, [isInitialised]);

    useEffect(() => {
        const { sessionId } = unleashClient.getContext();
        unleashClient.updateContext({ sessionId, userId: user?.uid });
    }, [user]);

    return (
        <FlagProvider unleashClient={unleashClient} startClient={false}>
            {children}
        </FlagProvider>
    );
};

export default Provider;
