import {
    useState,
    useEffect,
    useContext,
    createContext,
    ReactNode,
} from "react";
import firebase_app from "firebase/app";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

type Auth = ReturnType<typeof useProvideAuth>;
const authContext = createContext<Auth>({
    user: null,
    isCourtsite: false,
    newUserDetails: { name: "", phoneNumber: "" },
    isInitialised: false,
    signin: () => Promise.reject("not initialized"),
    signup: () => Promise.reject("not initialized"),
    hasPassAuth: () => false,
    signout: () => {
        throw new Error("not initialized");
    },
    sendPasswordResetEmail: () => Promise.reject("not initialized"),
    confirmPasswordReset: () => Promise.reject("not initialized"),
    reauthenticateUser: () => Promise.reject("not initialized"),
    updatePassword: () => Promise.reject("not initialized"),
    initGoogleAuth: () => Promise.reject("not initialized"),
    initFacebookAuth: () => Promise.reject("not initialized"),
    linkGoogleAuth: () => Promise.reject("not initialized"),
    linkFacebookAuth: () => Promise.reject("not initialized"),
    reauthenticateWithGoogle: () => Promise.reject("not initialized"),
    reauthenticateWithFacebook: () => Promise.reject("not initialized"),
    reload: () => {},
    hasUserPassword: () => Promise.reject("not initialized"),
});

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const FirebaseAuthProvider = ({
    children,
    app,
}: {
    children: ReactNode;
    app: firebase_app.app.App;
}): JSX.Element => {
    const auth = useProvideAuth(app);
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = (): Auth => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useProvideAuth = (app: firebase_app.app.App) => {
    const { t } = useTranslation("common");
    const isServerSide = typeof window === "undefined";

    const [user, setUser] = useState<firebase_app.User | null>(null);
    const [isInitialised, setIsInitialised] = useState(false);

    const isCourtsite = user?.email?.includes("courtsite.my") ?? false;

    // Firebase seems to have an issue where user.displayName & user.phoneNumber still returns as null despite both already existing. From what i've read online, profile updates do not affect auth tokens that have already been issued (onAuthStateChanged does not work in this case). So have to store the name & phone number in state and use it locally for the new user at least on the first run.
    const [newUserDetails, setNewUserDetails] = useState({
        name: "",
        phoneNumber: "",
    });

    // Wrap any Firebase methods we want to use making sure ...
    // ... to save the user to state.
    const signin = async ({
        email,
        password,
    }): Promise<firebase_app.User | null> => {
        const response = await app
            .auth()
            .signInWithEmailAndPassword(email, password);
        setUser(response.user);
        return response.user;
    };

    const signup = async ({
        email,
        password,
        name,
        phoneNumber,
    }): Promise<firebase_app.User | null> => {
        const response = await app
            .auth()
            .createUserWithEmailAndPassword(email, password);
        if (response.user) {
            setUser(response.user);
            setNewUserDetails({
                name: name,
                phoneNumber: phoneNumber,
            });
            response.user.updateProfile({
                displayName: name,
            });
        }
        return response.user;
    };

    const hasPassAuth = (user?: firebase_app.User): boolean => {
        if (!user) {
            return false;
        }
        for (let i = 0, l = user.providerData.length; i < l; i++) {
            const { providerId } = user.providerData[i] ?? {};
            if (providerId === "password") {
                return true;
            }
        }
        return false;
    };

    const signout = (
        onSignout?: () => void,
    ): ReturnType<typeof Modal.confirm> => {
        return Modal.confirm({
            title: t("areYouSure", "Are you sure?"),
            content: t(
                "areYouSureLogout",
                "Are you sure you want to log out from Courtsite?",
            ),
            onOk: () => {
                app.auth()
                    .signOut()
                    .then(() => {
                        setUser(null);
                    });
                if (onSignout) {
                    onSignout();
                }
            },
            onCancel: () => {},
            cancelText: t("buttonCancel", "Cancel"),
            okText: t("logOut", "Log out"),
            okButtonProps: {
                type: "primary",
                danger: true,
            },
            autoFocusButton: null,
        });
    };

    const sendPasswordResetEmail = async (email: string): Promise<boolean> => {
        await app.auth().sendPasswordResetEmail(email);
        return true;
    };

    const confirmPasswordReset = async (
        code: string,
        password: string,
    ): Promise<boolean> => {
        await app.auth().confirmPasswordReset(code, password);
        return true;
    };

    const reauthenticateUser = (
        password,
    ): Promise<firebase_app.auth.UserCredential> => {
        if (!user) throw Error("user not logged in");
        if (!user.email) throw Error("no user email");
        const credentials = firebase_app.auth.EmailAuthProvider.credential(
            user.email,
            password,
        );
        return user.reauthenticateWithCredential(credentials);
    };

    const updatePassword = (newPassword): Promise<void> => {
        if (!user) throw Error("user not logged in");
        return user.updatePassword(newPassword);
    };

    const hasUserPassword = (email: string): Promise<boolean> =>
        app
            .auth()
            .fetchSignInMethodsForEmail(email)
            .then((signInMethods: string[]) =>
                signInMethods.includes("password"),
            );

    const initGoogleAuth = (): Promise<firebase_app.auth.UserCredential> => {
        const provider = new firebase_app.auth.GoogleAuthProvider();

        return app.auth().signInWithPopup(provider);
    };

    const initFacebookAuth = (): Promise<firebase_app.auth.UserCredential> => {
        const provider = new firebase_app.auth.FacebookAuthProvider();

        return app.auth().signInWithPopup(provider);
    };

    const linkGoogleAuth = (): Promise<firebase_app.auth.UserCredential> => {
        if (!user) throw Error("user not logged in");
        const provider = new firebase_app.auth.GoogleAuthProvider();

        return user.linkWithPopup(provider);
    };

    const linkFacebookAuth = (): Promise<firebase_app.auth.UserCredential> => {
        if (!user) throw Error("user not logged in");
        const provider = new firebase_app.auth.FacebookAuthProvider();

        return user.linkWithPopup(provider);
    };

    const reauthenticateWithGoogle =
        (): Promise<firebase_app.auth.UserCredential> => {
            if (!user) throw Error("user not logged in");
            const provider = new firebase_app.auth.GoogleAuthProvider();

            return user.reauthenticateWithPopup(provider);
        };

    const reauthenticateWithFacebook =
        (): Promise<firebase_app.auth.UserCredential> => {
            if (!user) throw Error("user not logged in");
            const provider = new firebase_app.auth.FacebookAuthProvider();

            return user.reauthenticateWithPopup(provider);
        };

    const reload = (): void => {
        if (user) {
            user.reload().then(() => {
                const currentUser = app.auth().currentUser;
                setUser(currentUser);
            });
        }
    };
    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any ...
    // ... component that utilizes this hook to re-render with the ...
    // ... latest auth object.
    useEffect(() => {
        const unsubscribe = app.auth().onAuthStateChanged((user) => {
            if (user) {
                setUser(user);
                setIsInitialised(true);
            } else {
                setUser(null);
                setIsInitialised(true);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [app]);

    useEffect(() => {
        const idTokenChange = app.auth().onIdTokenChanged((user) => {
            if (user) {
                setUser(user);
            }
        });
        return () => idTokenChange();
    });

    // Return the user object and auth methods
    return {
        user,
        isCourtsite,
        newUserDetails,
        isInitialised: isServerSide ? true : isInitialised,
        signin,
        signup,
        hasPassAuth,
        signout,
        sendPasswordResetEmail,
        confirmPasswordReset,
        reauthenticateUser,
        updatePassword,
        initGoogleAuth,
        initFacebookAuth,
        linkGoogleAuth,
        linkFacebookAuth,
        reauthenticateWithGoogle,
        reauthenticateWithFacebook,
        reload,
        hasUserPassword,
    };
};
