import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

// Since BeforeInstallPromptEvent lacks official type definitions and is currently
// supported only in Chrome and Android, we define this interface ourselves for
// better type safety and clarity when handling install prompts.
interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
        outcome: "accepted" | "dismissed";
        platform: string;
    }>;
    prompt: () => Promise<void>;
}

declare global {
    interface WindowEventMap {
        beforeinstallprompt: BeforeInstallPromptEvent;
    }
    interface Navigator {
        standalone: string;
    }
}

const a2hsPromptCountKey = "a2hsPromptCount";

interface A2HSContextValue {
    prompt?: () => void;
    handleDismissEvent: () => void;
    iOSPrompt?: () => void;
    isSafariIOS: boolean;
}

const A2HSContext = createContext<A2HSContextValue | undefined>(undefined);

const useA2HSContext = (): A2HSContextValue => {
    const context = useContext(A2HSContext);
    if (!context)
        throw new Error("useA2HSContext must be used within an A2HSProvider");
    return context;
};

const A2HSProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const { prompt, handleDismissEvent } = useA2HS();
    const { iOSPrompt, isSafariIOS } = useA2HSIOS();

    return (
        <A2HSContext.Provider
            value={{ prompt, handleDismissEvent, iOSPrompt, isSafariIOS }}
        >
            {children}
        </A2HSContext.Provider>
    );
};

const useA2HS = (): { prompt?: () => void; handleDismissEvent: () => void } => {
    const [event, setEvent] = useState<BeforeInstallPromptEvent | undefined>();

    let prompt: (() => Promise<void>) | undefined;
    if (event) {
        prompt = async (): Promise<void> => {
            event.prompt();
            await event.userChoice;
        };
    }

    useEffect(() => {
        const stored = parseInt(
            localStorage.getItem(a2hsPromptCountKey) || "0",
            10,
        );
        const shouldShowA2HSPrompt = stored < 8;
        const ready = (e: BeforeInstallPromptEvent): void => {
            e.preventDefault();
            if (shouldShowA2HSPrompt) setEvent(e);
        };

        window.addEventListener("beforeinstallprompt", ready);
        return () => window.removeEventListener("beforeinstallprompt", ready);
    }, []);

    const handleDismissEvent = (): void => setEvent(undefined);

    return { prompt, handleDismissEvent };
};

const useA2HSIOS = (): { isSafariIOS: boolean; iOSPrompt?: () => void } => {
    const [event, setEvent] = useState<boolean>(false);
    const [isSafariIOS, setIsSafariIOS] = useState<boolean>(false);
    const iOSPromptRef = useRef<(() => void) | undefined>(undefined);

    useEffect(() => {
        const checkForIOS = (): void => {
            if (window.navigator.standalone || typeof window === "undefined")
                return;
            const stored = parseInt(
                localStorage.getItem(a2hsPromptCountKey) || "0",
                10,
            );
            const shouldShowA2HSPrompt = stored < 8;

            const ua = window.navigator.userAgent;
            const webkit = !!ua.match(/WebKit/i);
            const isIPad = !!ua.match(/iPad/i);
            const isIPhone = !!ua.match(/iPhone/i);
            const isIOS = isIPad || isIPhone;
            const isSafari = !!(
                ua.match(/Safari/i) &&
                !ua.match(/Chrome|CriOS|fxios|Opera|OPT\//)
            );
            const isIOSDevice = isIOS && webkit;
            setEvent(isIOSDevice && shouldShowA2HSPrompt);
            setIsSafariIOS(isIOSDevice && isSafari);

            if (event)
                iOSPromptRef.current = (): void => {
                    setEvent(false);
                    iOSPromptRef.current = undefined;
                };
        };
        checkForIOS();
        window.addEventListener(a2hsPromptCountKey, checkForIOS);
        return () => {
            window.removeEventListener(a2hsPromptCountKey, checkForIOS);
        };
    }, [event]);

    return { iOSPrompt: iOSPromptRef.current, isSafariIOS };
};

const hideA2HS = (): void => localStorage.setItem(a2hsPromptCountKey, "8");
const incrementPromptDismissalCount = (): void => {
    const stored = parseInt(
        localStorage.getItem(a2hsPromptCountKey) || "0",
        10,
    );
    const newCount = (stored + 1).toString();
    localStorage.setItem(a2hsPromptCountKey, newCount);
};

export {
    A2HSProvider,
    hideA2HS,
    incrementPromptDismissalCount,
    useA2HSContext,
};
