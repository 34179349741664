// info from : https://github.com/AsyrafHussin/malaysia-postcodes

type State = {
    name: string;
    city: {
        name: string;
        postcode: string[];
    }[];
};
const postcodesToState: State[] = [
    {
        name: "W.P Kuala Lumpur",
        city: [
            {
                name: "Kuala Lumpur",
                postcode: [
                    "50000",
                    "50050",
                    "50088",
                    "50100",
                    "50150",
                    "50200",
                    "50250",
                    "50300",
                    "50350",
                    "50400",
                    "50420",
                    "50450",
                    "50460",
                    "50470",
                    "50480",
                    "50489",
                    "50490",
                    "50500",
                    "50502",
                    "50504",
                    "50505",
                    "50506",
                    "50507",
                    "50508",
                    "50512",
                    "50514",
                    "50515",
                    "50519",
                    "50528",
                    "50529",
                    "50530",
                    "50532",
                    "50534",
                    "50536",
                    "50538",
                    "50540",
                    "50544",
                    "50546",
                    "50548",
                    "50550",
                    "50551",
                    "50552",
                    "50554",
                    "50556",
                    "50560",
                    "50562",
                    "50564",
                    "50566",
                    "50568",
                    "50572",
                    "50576",
                    "50578",
                    "50580",
                    "50582",
                    "50586",
                    "50588",
                    "50590",
                    "50592",
                    "50594",
                    "50596",
                    "50598",
                    "50599",
                    "50600",
                    "50603",
                    "50604",
                    "50605",
                    "50608",
                    "50609",
                    "50610",
                    "50612",
                    "50614",
                    "50620",
                    "50621",
                    "50622",
                    "50623",
                    "50626",
                    "50630",
                    "50632",
                    "50634",
                    "50636",
                    "50638",
                    "50640",
                    "50642",
                    "50644",
                    "50646",
                    "50648",
                    "50650",
                    "50652",
                    "50653",
                    "50656",
                    "50658",
                    "50660",
                    "50661",
                    "50662",
                    "50664",
                    "50666",
                    "50668",
                    "50670",
                    "50672",
                    "50673",
                    "50674",
                    "50676",
                    "50677",
                    "50678",
                    "50680",
                    "50682",
                    "50684",
                    "50688",
                    "50694",
                    "50700",
                    "50702",
                    "50704",
                    "50706",
                    "50708",
                    "50710",
                    "50712",
                    "50714",
                    "50716",
                    "50718",
                    "50720",
                    "50722",
                    "50724",
                    "50726",
                    "50728",
                    "50730",
                    "50732",
                    "50734",
                    "50736",
                    "50738",
                    "50740",
                    "50742",
                    "50744",
                    "50746",
                    "50748",
                    "50750",
                    "50752",
                    "50754",
                    "50758",
                    "50760",
                    "50762",
                    "50764",
                    "50766",
                    "50768",
                    "50770",
                    "50772",
                    "50774",
                    "50776",
                    "50778",
                    "50780",
                    "50782",
                    "50784",
                    "50786",
                    "50788",
                    "50790",
                    "50792",
                    "50794",
                    "50796",
                    "50798",
                    "50800",
                    "50802",
                    "50804",
                    "50806",
                    "50808",
                    "50810",
                    "50812",
                    "50814",
                    "50816",
                    "50818",
                    "50901",
                    "50902",
                    "50903",
                    "50904",
                    "50906",
                    "50907",
                    "50908",
                    "50909",
                    "50910",
                    "50911",
                    "50912",
                    "50913",
                    "50914",
                    "50915",
                    "50916",
                    "50917",
                    "50918",
                    "50919",
                    "50920",
                    "50921",
                    "50922",
                    "50923",
                    "50924",
                    "50925",
                    "50926",
                    "50927",
                    "50928",
                    "50929",
                    "50930",
                    "50931",
                    "50932",
                    "50933",
                    "50934",
                    "50935",
                    "50936",
                    "50937",
                    "50938",
                    "50939",
                    "50940",
                    "50941",
                    "50942",
                    "50943",
                    "50944",
                    "50945",
                    "50946",
                    "50947",
                    "50948",
                    "50949",
                    "50950",
                    "50988",
                    "50989",
                    "50990",
                    "51000",
                    "51100",
                    "51200",
                    "51300",
                    "51640",
                    "51700",
                    "51800",
                    "51900",
                    "51990",
                    "52000",
                    "52100",
                    "52109",
                    "52200",
                    "52210",
                    "52220",
                    "53000",
                    "53100",
                    "53200",
                    "53500",
                    "53700",
                    "53800",
                    "53990",
                    "54000",
                    "54050",
                    "54100",
                    "54200",
                    "54540",
                    "55000",
                    "55100",
                    "55200",
                    "55220",
                    "55300",
                    "55330",
                    "55700",
                    "55710",
                    "55720",
                    "55900",
                    "55902",
                    "55904",
                    "55906",
                    "55908",
                    "55910",
                    "55912",
                    "55914",
                    "55916",
                    "55918",
                    "55920",
                    "55922",
                    "55924",
                    "55926",
                    "55928",
                    "55930",
                    "55932",
                    "55934",
                    "55990",
                    "56000",
                    "56100",
                    "57000",
                    "57100",
                    "57200",
                    "57700",
                    "57990",
                    "58000",
                    "58100",
                    "58200",
                    "58209",
                    "58700",
                    "58990",
                    "59000",
                    "59001",
                    "59100",
                    "59200",
                    "59206",
                    "59700",
                    "59800",
                    "59990",
                    "60000",
                ],
            },
            {
                name: "Setapak",
                postcode: ["53300"],
            },
        ],
    },
    {
        name: "Johor",
        city: [
            {
                name: "Ayer Baloi",
                postcode: ["82100"],
            },
            {
                name: "Ayer Hitam",
                postcode: ["86100"],
            },
            {
                name: "Ayer Tawar 2",
                postcode: ["81920"],
            },
            {
                name: "Bandar Penawar",
                postcode: ["81930", "81960"],
            },
            {
                name: "Bandar Tenggara",
                postcode: ["81440"],
            },
            {
                name: "Batu Anam",
                postcode: ["85100"],
            },
            {
                name: "Batu Pahat",
                postcode: [
                    "83000",
                    "83010",
                    "83020",
                    "83030",
                    "83040",
                    "83050",
                    "86400",
                ],
            },
            {
                name: "Bekok",
                postcode: ["86500"],
            },
            {
                name: "Benut",
                postcode: ["82200"],
            },
            {
                name: "Bukit Gambir",
                postcode: ["84800"],
            },
            {
                name: "Bukit Pasir",
                postcode: ["84300"],
            },
            {
                name: "Chaah",
                postcode: ["85400"],
            },
            {
                name: "Endau",
                postcode: ["86900", "86999"],
            },
            {
                name: "Gelang Patah",
                postcode: ["81550", "81560"],
            },
            {
                name: "Gerisek",
                postcode: ["84700", "84710"],
            },
            {
                name: "Gugusan Taib Andak",
                postcode: ["81450"],
            },
            {
                name: "Jementah",
                postcode: ["85200", "85210"],
            },
            {
                name: "Johor Bahru",
                postcode: [
                    "80000",
                    "80050",
                    "80100",
                    "80110",
                    "80150",
                    "80200",
                    "80250",
                    "80300",
                    "80350",
                    "80400",
                    "80500",
                    "80502",
                    "80503",
                    "80504",
                    "80505",
                    "80506",
                    "80508",
                    "80514",
                    "80516",
                    "80517",
                    "80518",
                    "80519",
                    "80520",
                    "80529",
                    "80530",
                    "80532",
                    "80534",
                    "80536",
                    "80538",
                    "80540",
                    "80542",
                    "80546",
                    "80548",
                    "80550",
                    "80552",
                    "80558",
                    "80560",
                    "80564",
                    "80568",
                    "80570",
                    "80576",
                    "80578",
                    "80584",
                    "80586",
                    "80590",
                    "80592",
                    "80594",
                    "80596",
                    "80600",
                    "80604",
                    "80606",
                    "80608",
                    "80610",
                    "80612",
                    "80620",
                    "80622",
                    "80626",
                    "80628",
                    "80630",
                    "80632",
                    "80644",
                    "80646",
                    "80648",
                    "80658",
                    "80660",
                    "80662",
                    "80664",
                    "80668",
                    "80670",
                    "80672",
                    "80673",
                    "80676",
                    "80700",
                    "80710",
                    "80720",
                    "80730",
                    "80750",
                    "80800",
                    "80900",
                    "80902",
                    "80904",
                    "80906",
                    "80908",
                    "80988",
                    "80990",
                    "81100",
                    "81110",
                    "81120",
                    "81200",
                    "81250",
                    "81300",
                    "81310",
                ],
            },
            {
                name: "Kahang",
                postcode: ["86700"],
            },
            {
                name: "Kluang",
                postcode: ["86000", "86007"],
            },
            {
                name: "Kota Tinggi",
                postcode: ["81900", "81907", "81910"],
            },
            {
                name: "Kukup",
                postcode: ["82300"],
            },
            {
                name: "Kulai",
                postcode: [
                    "81000",
                    "81010",
                    "81020",
                    "81030",
                    "81040",
                    "81050",
                ],
            },
            {
                name: "Labis",
                postcode: ["85300"],
            },
            {
                name: "Layang-Layang",
                postcode: ["81850"],
            },
            {
                name: "Masai",
                postcode: ["81750", "81760"],
            },
            {
                name: "Mersing",
                postcode: ["86800", "86810"],
            },
            {
                name: "Muar",
                postcode: [
                    "84000",
                    "84007",
                    "84010",
                    "84020",
                    "84030",
                    "84040",
                    "84050",
                    "84100",
                    "84200",
                    "84300",
                    "84410",
                ],
            },
            {
                name: "Nusajaya",
                postcode: [
                    "79000",
                    "79100",
                    "79150",
                    "79200",
                    "79250",
                    "79502",
                    "79503",
                    "79504",
                    "79505",
                    "79511",
                    "79513",
                    "79514",
                    "79517",
                    "79518",
                    "79520",
                    "79521",
                    "79523",
                    "79532",
                    "79538",
                    "79540",
                    "79546",
                    "79548",
                    "79550",
                    "79552",
                    "79555",
                    "79570",
                    "79575",
                    "79576",
                    "79592",
                    "79601",
                    "79603",
                    "79605",
                    "79606",
                    "79612",
                    "79626",
                    "79630",
                    "79632",
                    "79646",
                    "79658",
                    "79660",
                    "79680",
                    "79681",
                    "79683",
                ],
            },
            {
                name: "Pagoh",
                postcode: ["84600"],
            },
            {
                name: "Paloh",
                postcode: ["86600"],
            },
            {
                name: "Panchor",
                postcode: ["84500"],
            },
            {
                name: "Parit Jawa",
                postcode: ["84150", "84160"],
            },
            {
                name: "Parit Raja",
                postcode: ["86400"],
            },
            {
                name: "Parit Sulong",
                postcode: ["83500"],
            },
            {
                name: "Pasir Gudang",
                postcode: ["81700"],
            },
            {
                name: "Pekan Nenas",
                postcode: ["71590", "81500"],
            },
            {
                name: "Pengerang",
                postcode: ["81600", "81620", "81650"],
            },
            {
                name: "Pontian",
                postcode: ["82000", "82010", "82020", "82030"],
            },
            {
                name: "Rengam",
                postcode: ["86300"],
            },
            {
                name: "Rengit",
                postcode: ["83100"],
            },
            {
                name: "Segamat",
                postcode: [
                    "85000",
                    "85010",
                    "85020",
                    "85030",
                    "85060",
                    "85070",
                    "85080",
                ],
            },
            {
                name: "Semerah",
                postcode: ["83600", "83610"],
            },
            {
                name: "Senai",
                postcode: ["81400", "81410"],
            },
            {
                name: "Senggarang",
                postcode: ["83200"],
            },
            {
                name: "Seri Gading",
                postcode: ["83300"],
            },
            {
                name: "Seri Medan",
                postcode: ["83400"],
            },
            {
                name: "Simpang Rengam",
                postcode: ["86200"],
            },
            {
                name: "Sungai Mati",
                postcode: ["84400", "84409"],
            },
            {
                name: "Tangkak",
                postcode: ["84900", "84990"],
            },
            {
                name: "Tioman",
                postcode: ["86800"],
            },
            {
                name: "Ulu Choh",
                postcode: ["81150"],
            },
            {
                name: "Ulu Tiram",
                postcode: ["81800", "81810"],
            },
            {
                name: "Yong Peng",
                postcode: ["83700", "83710"],
            },
        ],
    },
    {
        name: "Kedah",
        city: [
            {
                name: "Alor Setar",
                postcode: [
                    "05000",
                    "05050",
                    "05100",
                    "05150",
                    "05200",
                    "05250",
                    "05300",
                    "05350",
                    "05400",
                    "05450",
                    "05460",
                    "05500",
                    "05502",
                    "05503",
                    "05504",
                    "05505",
                    "05506",
                    "05508",
                    "05512",
                    "05514",
                    "05516",
                    "05517",
                    "05518",
                    "05520",
                    "05532",
                    "05534",
                    "05536",
                    "05538",
                    "05550",
                    "05551",
                    "05552",
                    "05556",
                    "05558",
                    "05560",
                    "05564",
                    "05576",
                    "05578",
                    "05580",
                    "05582",
                    "05586",
                    "05590",
                    "05592",
                    "05594",
                    "05600",
                    "05604",
                    "05610",
                    "05612",
                    "05614",
                    "05620",
                    "05621",
                    "05622",
                    "05626",
                    "05628",
                    "05630",
                    "05632",
                    "05644",
                    "05660",
                    "05661",
                    "05664",
                    "05670",
                    "05672",
                    "05673",
                    "05674",
                    "05675",
                    "05676",
                    "05680",
                    "05690",
                    "05696",
                    "05700",
                    "05710",
                    "05720",
                    "05990",
                    "06250",
                    "06509",
                    "06550",
                    "06570",
                    "06660",
                    "5110",
                    "5330",
                ],
            },
            {
                name: "Ayer Hitam",
                postcode: ["06150"],
            },
            {
                name: "Baling",
                postcode: ["09100"],
            },
            {
                name: "Bandar Baharu",
                postcode: ["34950"],
            },
            {
                name: "Bandar Bahru",
                postcode: ["14290", "14390"],
            },
            {
                name: "Bedong",
                postcode: ["08100", "08110", "8170"],
            },
            {
                name: "Bukit Kayu Hitam",
                postcode: ["06050"],
            },
            {
                name: "Changloon",
                postcode: ["06010"],
            },
            {
                name: "Gurun",
                postcode: ["08300", "08330", "08800"],
            },
            {
                name: "Jeniang",
                postcode: ["08320", "08700"],
            },
            {
                name: "Jitra",
                postcode: ["06000", "06007", "06009"],
            },
            {
                name: "Karangan",
                postcode: ["09700"],
            },
            {
                name: "Kepala Batas",
                postcode: ["06200", "06207", "06209"],
            },
            {
                name: "Kodiang",
                postcode: ["06100"],
            },
            {
                name: "Kota Kuala Muda",
                postcode: ["08500", "08507", "08509"],
            },
            {
                name: "Kota Sarang Semut",
                postcode: ["06800"],
            },
            {
                name: "Kuala Kedah",
                postcode: ["06600"],
            },
            {
                name: "Kuala Ketil",
                postcode: ["09300", "09310"],
            },
            {
                name: "Kuala Nerang",
                postcode: ["06300"],
            },
            {
                name: "Kuala Pegang",
                postcode: ["09110"],
            },
            {
                name: "Kulim",
                postcode: ["09000", "09007", "09009", "09010", "09020"],
            },
            {
                name: "Kupang",
                postcode: ["09200"],
            },
            {
                name: "Langgar",
                postcode: ["06500", "06507"],
            },
            {
                name: "Langkawi",
                postcode: ["07000", "07007", "07009", "07100", "7520"],
            },
            {
                name: "Lunas",
                postcode: ["09600"],
            },
            {
                name: "Merbok",
                postcode: ["08400", "08407", "08409"],
            },
            {
                name: "Padang Serai",
                postcode: ["09400", "09410"],
            },
            {
                name: "Pendang",
                postcode: [
                    "06700",
                    "06707",
                    "06709",
                    "06710",
                    "06720",
                    "06750",
                    "06760",
                ],
            },
            {
                name: "Pokok Sena",
                postcode: ["06350", "06400"],
            },
            {
                name: "Serdang",
                postcode: ["09800", "09810"],
            },
            {
                name: "Sik",
                postcode: ["08200", "08210", "08340"],
            },
            {
                name: "Simpang Empat",
                postcode: ["06650", "06680"],
            },
            {
                name: "Sungai Petani",
                postcode: ["08000", "08007", "08009", "08010", "08600"],
            },
            {
                name: "Yan",
                postcode: ["06900", "06910"],
            },
        ],
    },
    {
        name: "Kelantan",
        city: [
            {
                name: "Ayer Lanas",
                postcode: ["17700"],
            },
            {
                name: "Bachok",
                postcode: [
                    "16020",
                    "16030",
                    "16050",
                    "16060",
                    "16070",
                    "16090",
                    "16300",
                    "16310",
                    "16320",
                    "16350",
                    "16370",
                    "16390",
                ],
            },
            {
                name: "Cherang Ruku",
                postcode: ["16700"],
            },
            {
                name: "Dabong",
                postcode: ["18200"],
            },
            {
                name: "Gua Musang",
                postcode: ["18300"],
            },
            {
                name: "Jeli",
                postcode: ["17600"],
            },
            {
                name: "Kem Desa Pahlawan",
                postcode: ["16500"],
            },
            {
                name: "Ketereh",
                postcode: ["16450"],
            },
            {
                name: "Kota Bharu",
                postcode: [
                    "15000",
                    "15050",
                    "15100",
                    "15150",
                    "15159",
                    "15160",
                    "15200",
                    "15300",
                    "15350",
                    "15400",
                    "15500",
                    "15502",
                    "15503",
                    "15504",
                    "15505",
                    "15506",
                    "15508",
                    "15512",
                    "15514",
                    "15516",
                    "15517",
                    "15518",
                    "15519",
                    "15520",
                    "15524",
                    "15529",
                    "15532",
                    "15534",
                    "15536",
                    "15538",
                    "15540",
                    "15546",
                    "15548",
                    "15550",
                    "15551",
                    "15556",
                    "15558",
                    "15560",
                    "15564",
                    "15570",
                    "15572",
                    "15576",
                    "15578",
                    "15582",
                    "15586",
                    "15590",
                    "15592",
                    "15594",
                    "15596",
                    "15600",
                    "15604",
                    "15606",
                    "15608",
                    "15609",
                    "15610",
                    "15612",
                    "15614",
                    "15616",
                    "15620",
                    "15622",
                    "15623",
                    "15624",
                    "15626",
                    "15628",
                    "15630",
                    "15632",
                    "15634",
                    "15644",
                    "15646",
                    "15648",
                    "15658",
                    "15660",
                    "15661",
                    "15662",
                    "15664",
                    "15670",
                    "15672",
                    "15673",
                    "15674",
                    "15676",
                    "15680",
                    "15690",
                    "15700",
                    "15710",
                    "15720",
                    "15730",
                    "15740",
                    "15988",
                    "15990",
                    "16010",
                    "16100",
                    "16109",
                    "16150",
                ],
            },
            {
                name: "Kuala Balah",
                postcode: ["17610"],
            },
            {
                name: "Kuala Krai",
                postcode: ["18000", "18020", "18050"],
            },
            {
                name: "Machang",
                postcode: ["18500"],
            },
            {
                name: "Melor",
                postcode: ["16400"],
            },
            {
                name: "Pasir Mas",
                postcode: [
                    "17000",
                    "17007",
                    "17009",
                    "17010",
                    "17020",
                    "17030",
                    "17040",
                    "17050",
                    "17060",
                    "17070",
                ],
            },
            {
                name: "Pasir Puteh",
                postcode: ["16800"],
            },
            {
                name: "Pulai Chondong",
                postcode: ["16600"],
            },
            {
                name: "Rantau Panjang",
                postcode: ["17200"],
            },
            {
                name: "Selising",
                postcode: ["16810"],
            },
            {
                name: "Tanah Merah",
                postcode: [
                    "17500",
                    "17507",
                    "17509",
                    "17510",
                    "17520",
                    "17599",
                ],
            },
            {
                name: "Temangan",
                postcode: ["18400"],
            },
            {
                name: "Tumpat",
                postcode: ["16080", "16200", "16210"],
            },
            {
                name: "Wakaf Bharu",
                postcode: ["16040", "16250", "16266"],
            },
        ],
    },
    {
        name: "Melaka",
        city: [
            {
                name: "Alor Gajah",
                postcode: ["78000", "78009"],
            },
            {
                name: "Asahan",
                postcode: ["77100", "77109"],
            },
            {
                name: "Ayer Keroh",
                postcode: ["75450"],
            },
            {
                name: "Bemban",
                postcode: ["77200"],
            },
            {
                name: "Durian Tunggal",
                postcode: ["76100", "76109"],
            },
            {
                name: "Jasin",
                postcode: ["77000", "77007", "77008", "77009"],
            },
            {
                name: "Kem Trendak",
                postcode: ["76200"],
            },
            {
                name: "Kuala Sungai Baru",
                postcode: ["78200"],
            },
            {
                name: "Lubok China",
                postcode: ["78100"],
            },
            {
                name: "Masjid Tanah",
                postcode: ["78300", "78307", "78309"],
            },
            {
                name: "Melaka",
                postcode: [],
            },
            {
                name: "Merlimau",
                postcode: [],
            },
            {
                name: "Selandar",
                postcode: [],
            },
            {
                name: "Sungai Rambai",
                postcode: [],
            },
            {
                name: "Sungai Udang",
                postcode: [],
            },
            {
                name: "Tanjong Kling",
                postcode: [],
            },
        ],
    },
    {
        name: "Negeri Sembilan",
        city: [
            {
                name: "Bahau",
                postcode: ["72100", "72107", "72109"],
            },
            {
                name: "Bandar Baru Enstek",
                postcode: ["71760"],
            },
            {
                name: "Bandar Baru Serting",
                postcode: ["72130"],
            },
            {
                name: "Bandar Seri Jempol",
                postcode: ["72120", "72127", "72129"],
            },
            {
                name: "Batu Kikir",
                postcode: ["72200", "72207", "72209"],
            },
            {
                name: "Gemas",
                postcode: ["73400", "73409", "73410", "73420", "73480"],
            },
            {
                name: "Gemencheh",
                postcode: ["73200", "73207", "73209", "73300", "73309"],
            },
            {
                name: "Johol",
                postcode: ["73100", "73109"],
            },
            {
                name: "Kota",
                postcode: ["71350", "71359"],
            },
            {
                name: "Kuala Klawang",
                postcode: ["71600", "71609", "71650", "71659"],
            },
            {
                name: "Kuala Pilah",
                postcode: [
                    "72000",
                    "72007",
                    "72009",
                    "72500",
                    "72507",
                    "72509",
                ],
            },
            {
                name: "Labu",
                postcode: ["71900", "71907", "71909"],
            },
            {
                name: "Linggi",
                postcode: ["71150", "71159"],
            },
            {
                name: "Mantin",
                postcode: ["71700", "71707", "71709", "71750", "71759"],
            },
            {
                name: "Nilai",
                postcode: ["71800", "71807", "71809", "71850"],
            },
            {
                name: "Port Dickson",
                postcode: [
                    "71000",
                    "71007",
                    "71009",
                    "71010",
                    "71960",
                    "71999",
                ],
            },
            {
                name: "Pusat  Bandar Palong",
                postcode: ["73430", "73440", "73450", "73460", "73470"],
            },
            {
                name: "Rantau",
                postcode: ["71100", "71109", "71200", "71209"],
            },
            {
                name: "Rembau",
                postcode: ["71300", "71309", "71400", "71409"],
            },
            {
                name: "Rompin",
                postcode: ["73500", "73507", "73509"],
            },
            {
                name: "Seremban",
                postcode: [
                    "70000",
                    "70100",
                    "70200",
                    "70300",
                    "70400",
                    "70450",
                    "70500",
                    "70502",
                    "70503",
                    "70504",
                    "70505",
                    "70506",
                    "70508",
                    "70512",
                    "70516",
                    "70517",
                    "70518",
                    "70532",
                    "70534",
                    "70536",
                    "70540",
                    "70546",
                    "70548",
                    "70550",
                    "70551",
                    "70558",
                    "70560",
                    "70564",
                    "70570",
                    "70572",
                    "70576",
                    "70578",
                    "70582",
                    "70586",
                    "70590",
                    "70592",
                    "70594",
                    "70596",
                    "70600",
                    "70604",
                    "70606",
                    "70608",
                    "70609",
                    "70610",
                    "70620",
                    "70626",
                    "70628",
                    "70632",
                    "70634",
                    "70644",
                    "70646",
                    "70648",
                    "70658",
                    "70664",
                    "70670",
                    "70672",
                    "70673",
                    "70674",
                    "70676",
                    "70690",
                    "70700",
                    "70710",
                    "70720",
                    "70730",
                    "70740",
                    "70750",
                    "70990",
                    "71450",
                    "71459",
                    "71770",
                    "71950",
                ],
            },
            {
                name: "Simpang Durian",
                postcode: ["72400", "72409"],
            },
            {
                name: "Simpang Pertang",
                postcode: ["72300", "72307", "72309"],
            },
            {
                name: "Si Rusa",
                postcode: ["71050", "71059", "71250", "71259"],
            },
            {
                name: "Tampin",
                postcode: ["73000", "73007", "73009"],
            },
            {
                name: "Tanjong Ipoh",
                postcode: ["71500", "71509", "71550", "71559"],
            },
        ],
    },
    {
        name: "Pahang",
        city: [
            {
                name: "Balok",
                postcode: ["26080", "26100", "26150", "26190"],
            },
            {
                name: "Bandar Pusat  Jengka",
                postcode: [
                    "26400",
                    "26410",
                    "26420",
                    "26430",
                    "26440",
                    "26450",
                    "26460",
                    "26485",
                    "26490",
                    "27080",
                ],
            },
            {
                name: "Bandar Tun Abdul Razak",
                postcode: ["26900", "26999"],
            },
            {
                name: "Benta",
                postcode: ["27300", "27310"],
            },
            {
                name: "Bentong",
                postcode: [
                    "28700",
                    "28707",
                    "28709",
                    "28730",
                    "28740",
                    "28750",
                ],
            },
            {
                name: "Brinchang",
                postcode: ["39100"],
            },
            {
                name: "Bukit Fraser",
                postcode: ["49000"],
            },
            {
                name: "Bukit Goh",
                postcode: ["26050", "26090"],
            },
            {
                name: "Chenor",
                postcode: ["28100"],
            },
            {
                name: "Chini",
                postcode: ["26690"],
            },
            {
                name: "Damak",
                postcode: ["27030"],
            },
            {
                name: "Dong",
                postcode: ["27400"],
            },
            {
                name: "Gambang",
                postcode: [
                    "26300",
                    "26310",
                    "26320",
                    "26330",
                    "26340",
                    "26350",
                    "26360",
                    "26370",
                ],
            },
            {
                name: "Genting Highlands",
                postcode: ["69000"],
            },
            {
                name: "Jaya Gading",
                postcode: ["26250"],
            },
            {
                name: "Jerantut",
                postcode: [
                    "27000",
                    "27010",
                    "27020",
                    "27040",
                    "27050",
                    "27060",
                    "27070",
                    "27090",
                    "27150",
                ],
            },
            {
                name: "Karak",
                postcode: ["28600", "28610", "28620"],
            },
            {
                name: "Kemayan",
                postcode: ["28340", "28380"],
            },
            {
                name: "Kuala Krau",
                postcode: ["28050"],
            },
            {
                name: "Kuala Lipis",
                postcode: ["27200", "27207", "27209", "27210"],
            },
            {
                name: "Kuala Rompin",
                postcode: ["26800", "26810", "26820"],
            },
            {
                name: "Kuantan",
                postcode: [
                    "25000",
                    "25020",
                    "25040",
                    "25050",
                    "25100",
                    "25150",
                    "25200",
                    "25250",
                    "25300",
                    "25320",
                    "25350",
                    "25500",
                    "25502",
                    "25503",
                    "25504",
                    "25505",
                    "25506",
                    "25508",
                    "25509",
                    "25512",
                    "25514",
                    "25516",
                    "25517",
                    "25518",
                    "25520",
                    "25524",
                    "25529",
                    "25532",
                    "25534",
                    "25536",
                    "25538",
                    "25540",
                    "25546",
                    "25548",
                    "25550",
                    "25551",
                    "25552",
                    "25556",
                    "25558",
                    "25560",
                    "25564",
                    "25570",
                    "25576",
                    "25578",
                    "25582",
                    "25584",
                    "25586",
                    "25590",
                    "25592",
                    "25594",
                    "25596",
                    "25598",
                    "25600",
                    "25604",
                    "25606",
                    "25608",
                    "25609",
                    "25610",
                    "25612",
                    "25614",
                    "25620",
                    "25622",
                    "25626",
                    "25628",
                    "25630",
                    "25632",
                    "25644",
                    "25646",
                    "25648",
                    "25656",
                    "25660",
                    "25661",
                    "25662",
                    "25670",
                    "25672",
                    "25673",
                    "25674",
                    "25676",
                    "25690",
                    "25700",
                    "25710",
                    "25720",
                    "25730",
                    "25740",
                    "25750",
                    "25990",
                    "26000",
                    "26010",
                    "26040",
                    "26060",
                    "26070",
                    "26140",
                    "26180",
                ],
            },
            {
                name: "Lanchang",
                postcode: ["28500"],
            },
            {
                name: "Lurah Bilut",
                postcode: ["28800"],
            },
            {
                name: "Maran",
                postcode: ["26500"],
            },
            {
                name: "Mentakab",
                postcode: ["28400", "28407", "28409"],
            },
            {
                name: "Muadzam Shah",
                postcode: ["26700"],
            },
            {
                name: "Padang Tengku",
                postcode: ["27100"],
            },
            {
                name: "Pekan",
                postcode: [
                    "26600",
                    "26607",
                    "26609",
                    "26610",
                    "26620",
                    "26630",
                    "26640",
                    "26650",
                    "26660",
                    "26680",
                ],
            },
            {
                name: "Raub",
                postcode: [
                    "27600",
                    "27607",
                    "27609",
                    "27610",
                    "27620",
                    "27630",
                    "27670",
                ],
            },
            {
                name: "Ringlet",
                postcode: ["39200"],
            },
            {
                name: "Sega",
                postcode: ["27660"],
            },
            {
                name: "Sungai Koyan",
                postcode: ["27650"],
            },
            {
                name: "Sungai Lembing",
                postcode: ["26200"],
            },
            {
                name: "Sungai Ruan",
                postcode: ["27500"],
            },
            {
                name: "Tanah Rata",
                postcode: ["39000", "39007", "39009", "39010"],
            },
            {
                name: "Temerloh",
                postcode: [
                    "28000",
                    "28007",
                    "28009",
                    "28010",
                    "28020",
                    "28030",
                    "28040",
                ],
            },
            {
                name: "Triang",
                postcode: [
                    "28200",
                    "28300",
                    "28310",
                    "28320",
                    "28330",
                    "28350",
                ],
            },
        ],
    },
    {
        name: "Penang",
        city: [
            {
                name: "Ayer Itam",
                postcode: ["11500"],
            },
            {
                name: "Balik Pulau",
                postcode: ["11000", "11010", "11020"],
            },
            {
                name: "Batu Ferringhi",
                postcode: ["11100"],
            },
            {
                name: "Batu Maung",
                postcode: ["11960"],
            },
            {
                name: "Bayan Lepas",
                postcode: ["11900", "11902", "11910", "11920", "11950"],
            },
            {
                name: "Bukit Mertajam",
                postcode: ["14000", "14007", "14009", "14020"],
            },
            {
                name: "Butterworth",
                postcode: [
                    "12000",
                    "12100",
                    "12200",
                    "12300",
                    "12344",
                    "12345",
                    "12700",
                    "12710",
                    "12720",
                    "12990",
                    "13000",
                    "13009",
                    "13020",
                    "13030",
                    "13050",
                    "13400",
                    "13409",
                    "13800",
                ],
            },
            {
                name: "Gelugor",
                postcode: ["11700"],
            },
            {
                name: "Jelutong",
                postcode: ["11600", "11609"],
            },
            {
                name: "Kepala Batas",
                postcode: ["13200", "13210", "13220"],
            },
            {
                name: "Kubang Semang",
                postcode: ["14400"],
            },
            {
                name: "Nibong Tebal",
                postcode: ["14300", "14310", "14320"],
            },
            {
                name: "Penaga",
                postcode: ["13100", "13110"],
            },
            {
                name: "Penang Hill",
                postcode: ["11300"],
            },
            {
                name: "Perai",
                postcode: ["13600", "13690", "13700"],
            },
            {
                name: "Permatang Pauh",
                postcode: ["13500"],
            },
            {
                name: "Pulau Pinang",
                postcode: [
                    "10000",
                    "10020",
                    "10050",
                    "10100",
                    "10110",
                    "10150",
                    "10160",
                    "10200",
                    "10250",
                    "10300",
                    "10350",
                    "10400",
                    "10420",
                    "10450",
                    "10460",
                    "10470",
                    "10500",
                    "10502",
                    "10503",
                    "10504",
                    "10505",
                    "10506",
                    "10508",
                    "10512",
                    "10514",
                    "10516",
                    "10518",
                    "10520",
                    "10524",
                    "10534",
                    "10538",
                    "10540",
                    "10542",
                    "10546",
                    "10550",
                    "10551",
                    "10552",
                    "10558",
                    "10560",
                    "10564",
                    "10566",
                    "10570",
                    "10576",
                    "10578",
                    "10582",
                    "10590",
                    "10592",
                    "10593",
                    "10594",
                    "10596",
                    "10600",
                    "10604",
                    "10609",
                    "10610",
                    "10612",
                    "10620",
                    "10622",
                    "10626",
                    "10628",
                    "10634",
                    "10646",
                    "10648",
                    "10660",
                    "10661",
                    "10662",
                    "10668",
                    "10670",
                    "10672",
                    "10673",
                    "10674",
                    "10676",
                    "10690",
                    "10710",
                    "10720",
                    "10730",
                    "10740",
                    "10750",
                    "10760",
                    "10770",
                    "10780",
                    "10790",
                    "10800",
                    "10810",
                    "10820",
                    "10830",
                    "10840",
                    "10850",
                    "10910",
                    "10920",
                    "10990",
                    "11050",
                    "11060",
                    "11400",
                    "11409",
                    "11460",
                ],
            },
            {
                name: "Simpang Ampat",
                postcode: ["14100", "14101", "14110", "14120"],
            },
            {
                name: "Sungai Jawi",
                postcode: ["14200"],
            },
            {
                name: "Tanjong Bungah",
                postcode: ["11200"],
            },
            {
                name: "Tasek Gelugor",
                postcode: ["13300", "13310"],
            },
            {
                name: "Usm Pulau Pinang",
                postcode: ["11800"],
            },
        ],
    },
    {
        name: "Perak",
        city: [
            {
                name: "Ayer Tawar",
                postcode: ["32400"],
            },
            {
                name: "Bagan Datoh",
                postcode: ["36100"],
            },
            {
                name: "Bagan Serai",
                postcode: ["34300", "34310"],
            },
            {
                name: "Bandar Seri Iskandar",
                postcode: ["32610"],
            },
            {
                name: "Batu Gajah",
                postcode: ["31000", "31007", "31009"],
            },
            {
                name: "Batu Kurau",
                postcode: ["34500", "34510", "34520"],
            },
            {
                name: "Behrang Stesen",
                postcode: ["35950"],
            },
            {
                name: "Bidor",
                postcode: ["35500"],
            },
            {
                name: "Bota",
                postcode: ["32600"],
            },
            {
                name: "Bruas",
                postcode: ["32700"],
            },
            {
                name: "Changkat Jering",
                postcode: ["34850"],
            },
            {
                name: "Changkat Keruing",
                postcode: ["32500"],
            },
            {
                name: "Chemor",
                postcode: ["31200"],
            },
            {
                name: "Chenderiang",
                postcode: ["35300"],
            },
            {
                name: "Chenderong Balai",
                postcode: ["36600"],
            },
            {
                name: "Chikus",
                postcode: ["36750"],
            },
            {
                name: "Enggor",
                postcode: ["33600"],
            },
            {
                name: "Gerik",
                postcode: ["33300", "33310", "33320"],
            },
            {
                name: "Gopeng",
                postcode: ["31600", "31610"],
            },
            {
                name: "Hutan Melintang",
                postcode: ["36400"],
            },
            {
                name: "Intan",
                postcode: ["33200"],
            },
            {
                name: "Ipoh",
                postcode: [
                    "30000",
                    "30010",
                    "30020",
                    "30100",
                    "30200",
                    "30205",
                    "30250",
                    "30300",
                    "30350",
                    "30400",
                    "30450",
                    "30500",
                    "30502",
                    "30503",
                    "30504",
                    "30505",
                    "30506",
                    "30508",
                    "30510",
                    "30512",
                    "30516",
                    "30517",
                    "30518",
                    "30519",
                    "30520",
                    "30524",
                    "30532",
                    "30534",
                    "30536",
                    "30540",
                    "30542",
                    "30546",
                    "30548",
                    "30550",
                    "30551",
                    "30552",
                    "30554",
                    "30556",
                    "30560",
                    "30564",
                    "30570",
                    "30576",
                    "30580",
                    "30582",
                    "30586",
                    "30590",
                    "30592",
                    "30594",
                    "30596",
                    "30600",
                    "30604",
                    "30606",
                    "30609",
                    "30610",
                    "30612",
                    "30614",
                    "30620",
                    "30621",
                    "30622",
                    "30626",
                    "30628",
                    "30630",
                    "30632",
                    "30634",
                    "30644",
                    "30646",
                    "30648",
                    "30656",
                    "30658",
                    "30660",
                    "30661",
                    "30662",
                    "30664",
                    "30668",
                    "30670",
                    "30673",
                    "30674",
                    "30676",
                    "30682",
                    "30690",
                    "30700",
                    "30710",
                    "30720",
                    "30730",
                    "30740",
                    "30750",
                    "30760",
                    "30770",
                    "30780",
                    "30790",
                    "30800",
                    "30810",
                    "30820",
                    "30830",
                    "30840",
                    "30900",
                    "30902",
                    "30904",
                    "30906",
                    "30908",
                    "30910",
                    "30912",
                    "30988",
                    "30990",
                    "31350",
                    "31400",
                    "31407",
                    "31409",
                    "31450",
                    "31500",
                    "31650",
                    "31672",
                ],
            },
            {
                name: "Jeram",
                postcode: ["31850"],
            },
            {
                name: "Kampar",
                postcode: ["31900", "31907", "31909", "31910"],
            },
            {
                name: "Kampung Gajah",
                postcode: ["36800", "36810"],
            },
            {
                name: "Kampung Kepayang",
                postcode: ["31300"],
            },
            {
                name: "Kamunting",
                postcode: ["34600"],
            },
            {
                name: "Kuala Kangsar",
                postcode: [
                    "33000",
                    "33007",
                    "33009",
                    "33010",
                    "33020",
                    "33030",
                    "33040",
                ],
            },
            {
                name: "Kuala Kurau",
                postcode: ["34350"],
            },
            {
                name: "Kuala Sepetang",
                postcode: ["34650"],
            },
            {
                name: "Lambor Kanan",
                postcode: ["32900", "32999"],
            },
            {
                name: "Langkap",
                postcode: ["36700"],
            },
            {
                name: "Lenggong",
                postcode: ["33400", "33410", "33420"],
            },
            {
                name: "Lumut",
                postcode: ["32200"],
            },
            {
                name: "Malim Nawar",
                postcode: ["31700"],
            },
            {
                name: "Mambang Di Awan",
                postcode: ["31920", "31950"],
            },
            {
                name: "Manong",
                postcode: ["33800"],
            },
            {
                name: "Matang",
                postcode: ["34750"],
            },
            {
                name: "Padang Rengas",
                postcode: ["33700"],
            },
            {
                name: "Pangkor",
                postcode: ["32300"],
            },
            {
                name: "Pantai Remis",
                postcode: ["34900", "34910"],
            },
            {
                name: "Parit",
                postcode: ["32800", "32810"],
            },
            {
                name: "Parit Buntar",
                postcode: ["34200"],
            },
            {
                name: "Pengkalan Hulu",
                postcode: ["33100"],
            },
            {
                name: "Pusing",
                postcode: ["31550", "31560"],
            },
            {
                name: "Rantau Panjang",
                postcode: ["34140", "42160"],
            },
            {
                name: "Sauk",
                postcode: ["33500"],
            },
            {
                name: "Selama",
                postcode: ["34100", "34120", "34130"],
            },
            {
                name: "Selekoh",
                postcode: ["36200", "36207", "36209"],
            },
            {
                name: "Seri Manjong",
                postcode: ["32040", "32050"],
            },
            {
                name: "Simpang",
                postcode: ["34700"],
            },
            {
                name: "Simpang Ampat Semanggol",
                postcode: ["34400"],
            },
            {
                name: "Sitiawan",
                postcode: ["32000", "32010", "32020"],
            },
            {
                name: "Slim River",
                postcode: ["35800", "35820"],
            },
            {
                name: "Sungai Siput",
                postcode: ["31050", "31100", "31120"],
            },
            {
                name: "Sungai Sumun",
                postcode: ["36300", "36307", "36309"],
            },
            {
                name: "Sungkai",
                postcode: ["35600"],
            },
            {
                name: "Taiping",
                postcode: [
                    "34000",
                    "34007",
                    "34008",
                    "34009",
                    "34010",
                    "34020",
                    "34030",
                ],
            },
            {
                name: "Tanjong Malim",
                postcode: ["35900", "35907", "35909", "35910"],
            },
            {
                name: "Tanjong Piandang",
                postcode: ["34250"],
            },
            {
                name: "Tanjong Rambutan",
                postcode: ["31250"],
            },
            {
                name: "Tanjong Tualang",
                postcode: ["31800"],
            },
            {
                name: "Tapah",
                postcode: ["35000", "35007", "35009"],
            },
            {
                name: "Tapah Road",
                postcode: ["35400"],
            },
            {
                name: "Teluk Intan",
                postcode: [
                    "36000",
                    "36007",
                    "36008",
                    "36009",
                    "36010",
                    "36020",
                    "36030",
                    "36110",
                ],
            },
            {
                name: "Temoh",
                postcode: ["35350"],
            },
            {
                name: "Tldm Lumut",
                postcode: ["32100"],
            },
            {
                name: "Trolak",
                postcode: ["35700"],
            },
            {
                name: "Trong",
                postcode: ["34800"],
            },
            {
                name: "Tronoh",
                postcode: ["31750"],
            },
            {
                name: "Ulu Bernam",
                postcode: ["36500"],
            },
            {
                name: "Ulu Kinta",
                postcode: ["31150", "31199"],
            },
        ],
    },
    {
        name: "Perlis",
        city: [
            {
                name: "Arau",
                postcode: ["02600", "02607", "02609"],
            },
            {
                name: "Kaki Bukit",
                postcode: ["02200"],
            },
            {
                name: "Kangar",
                postcode: [
                    "01000",
                    "01007",
                    "01009",
                    "01500",
                    "01502",
                    "01503",
                    "01504",
                    "01505",
                    "01506",
                    "01508",
                    "01512",
                    "01514",
                    "01516",
                    "01517",
                    "01518",
                    "01524",
                    "01529",
                    "01532",
                    "01538",
                    "01540",
                    "01546",
                    "01550",
                    "01551",
                    "01556",
                    "01560",
                    "01564",
                    "01570",
                    "01572",
                    "01576",
                    "01578",
                    "01582",
                    "01586",
                    "01590",
                    "01592",
                    "01594",
                    "01596",
                    "01598",
                    "01600",
                    "01604",
                    "01606",
                    "01608",
                    "01609",
                    "01610",
                    "01612",
                    "01614",
                    "01620",
                    "01622",
                    "01626",
                    "01628",
                    "01630",
                    "01632",
                    "01634",
                    "01644",
                    "01646",
                    "01648",
                    "01660",
                    "01664",
                    "01670",
                    "01672",
                    "01673",
                    "01674",
                    "01676",
                    "01680",
                    "01694",
                    "02400",
                    "02450",
                    "02500",
                    "1750",
                ],
            },
            {
                name: "Kuala Perlis",
                postcode: ["02000"],
            },
            {
                name: "Padang Besar",
                postcode: ["02100"],
            },
            {
                name: "Simpang Ampat",
                postcode: ["02700", "02707", "02709", "02800"],
            },
        ],
    },
    {
        name: "Sabah",
        city: [
            {
                name: "Beaufort",
                postcode: ["89800", "89807", "89808", "89809"],
            },
            {
                name: "Beluran",
                postcode: ["90100", "90107", "90109"],
            },
            {
                name: "Beverly",
                postcode: ["88700", "89260"],
            },
            {
                name: "Bongawan",
                postcode: ["89700", "89707", "89708", "89709"],
            },
            {
                name: "Inanam",
                postcode: ["88857"],
            },
            {
                name: "Keningau",
                postcode: ["89000", "89007", "89008", "89009"],
            },
            {
                name: "Kota Belud",
                postcode: ["89150", "89157", "89158", "89159"],
            },
            {
                name: "Kota Kinabalu",
                postcode: [
                    "88000",
                    "88100",
                    "88110",
                    "88150",
                    "88200",
                    "88220",
                    "88300",
                    "88400",
                    "88450",
                    "88458",
                    "88460",
                    "88500",
                    "88502",
                    "88504",
                    "88505",
                    "88506",
                    "88508",
                    "88510",
                    "88512",
                    "88514",
                    "88516",
                    "88518",
                    "88520",
                    "88526",
                    "88527",
                    "88532",
                    "88534",
                    "88538",
                    "88540",
                    "88546",
                    "88550",
                    "88551",
                    "88552",
                    "88554",
                    "88556",
                    "88558",
                    "88560",
                    "88562",
                    "88564",
                    "88566",
                    "88568",
                    "88570",
                    "88572",
                    "88576",
                    "88580",
                    "88582",
                    "88586",
                    "88590",
                    "88592",
                    "88594",
                    "88596",
                    "88598",
                    "88600",
                    "88602",
                    "88604",
                    "88606",
                    "88608",
                    "88609",
                    "88610",
                    "88612",
                    "88614",
                    "88617",
                    "88618",
                    "88620",
                    "88621",
                    "88622",
                    "88624",
                    "88626",
                    "88628",
                    "88630",
                    "88632",
                    "88634",
                    "88644",
                    "88646",
                    "88648",
                    "88656",
                    "88658",
                    "88660",
                    "88661",
                    "88662",
                    "88670",
                    "88672",
                    "88673",
                    "88675",
                    "88676",
                    "88680",
                    "88690",
                    "88740",
                    "88757",
                    "88758",
                    "88759",
                    "88760",
                    "88761",
                    "88762",
                    "88763",
                    "88764",
                    "88765",
                    "88766",
                    "88767",
                    "88768",
                    "88769",
                    "88770",
                    "88771",
                    "88772",
                    "88773",
                    "88774",
                    "88775",
                    "88776",
                    "88777",
                    "88778",
                    "88779",
                    "88780",
                    "88781",
                    "88782",
                    "88783",
                    "88784",
                    "88785",
                    "88786",
                    "88787",
                    "88788",
                    "88789",
                    "88790",
                    "88800",
                    "88801",
                    "88802",
                    "88803",
                    "88804",
                    "88805",
                    "88806",
                    "88807",
                    "88808",
                    "88809",
                    "88810",
                    "88811",
                    "88812",
                    "88813",
                    "88814",
                    "88815",
                    "88816",
                    "88817",
                    "88818",
                    "88819",
                    "88820",
                    "88821",
                    "88822",
                    "88823",
                    "88824",
                    "88825",
                    "88826",
                    "88827",
                    "88828",
                    "88829",
                    "88830",
                    "88831",
                    "88832",
                    "88833",
                    "88834",
                    "88835",
                    "88836",
                    "88837",
                    "88838",
                    "88839",
                    "88840",
                    "88841",
                    "88842",
                    "88843",
                    "88844",
                    "88845",
                    "88846",
                    "88847",
                    "88848",
                    "88849",
                    "88850",
                    "88851",
                    "88852",
                    "88853",
                    "88854",
                    "88855",
                    "88860",
                    "88861",
                    "88862",
                    "88863",
                    "88865",
                    "88866",
                    "88867",
                    "88868",
                    "88869",
                    "88870",
                    "88871",
                    "88872",
                    "88873",
                    "88874",
                    "88875",
                    "88900",
                    "88901",
                    "88902",
                    "88903",
                    "88904",
                    "88905",
                    "88906",
                    "88988",
                    "88990",
                    "88991",
                    "88992",
                    "88993",
                    "88994",
                    "88995",
                    "88996",
                    "88997",
                    "88998",
                    "88999",
                ],
            },
            {
                name: "Kota Kinabatangan",
                postcode: ["90200"],
            },
            {
                name: "Kota Marudu",
                postcode: ["89100", "89107", "89108", "89109"],
            },
            {
                name: "Kuala Penyu",
                postcode: ["89740", "89747", "89748", "89749"],
            },
            {
                name: "Kudat",
                postcode: ["89050", "89057", "89058", "89059"],
            },
            {
                name: "Kunak",
                postcode: ["91200", "91207", "91209"],
            },
            {
                name: "Lahad Datu",
                postcode: [
                    "91100",
                    "91108",
                    "91109",
                    "91110",
                    "91111",
                    "91112",
                    "91113",
                    "91114",
                    "91115",
                    "91116",
                    "91117",
                    "91118",
                    "91119",
                    "91120",
                    "91121",
                    "91122",
                    "91123",
                    "91124",
                    "91125",
                    "91126",
                    "91127",
                    "91128",
                    "91150",
                ],
            },
            {
                name: "Likas",
                postcode: ["88856"],
            },
            {
                name: "Membakut",
                postcode: ["89720", "89727", "89728", "89729"],
            },
            {
                name: "Menumbok",
                postcode: ["89760", "89767", "89768", "89769"],
            },
            {
                name: "Nabawan",
                postcode: ["89950", "89957", "89958", "89959"],
            },
            {
                name: "Pamol",
                postcode: ["90400"],
            },
            {
                name: "Papar",
                postcode: ["89600", "89607", "89608", "89609"],
            },
            {
                name: "Penampang",
                postcode: ["89500", "89507", "89508", "89509"],
            },
            {
                name: "Putatan",
                postcode: ["88721", "88722", "88723", "88724", "88725"],
            },
            {
                name: "Ranau",
                postcode: [
                    "89300",
                    "89307",
                    "89308",
                    "89309",
                    "89320",
                    "89350",
                    "89357",
                ],
            },
            {
                name: "Sandakan",
                postcode: [
                    "90000",
                    "90008",
                    "90009",
                    "90300",
                    "90307",
                    "90700",
                    "90701",
                    "90702",
                    "90703",
                    "90704",
                    "90705",
                    "90706",
                    "90707",
                    "90708",
                    "90709",
                    "90711",
                    "90712",
                    "90713",
                    "90714",
                    "90715",
                    "90716",
                    "90717",
                    "90718",
                    "90719",
                    "90720",
                    "90721",
                    "90722",
                    "90723",
                    "90724",
                    "90725",
                    "90726",
                    "90727",
                    "90728",
                    "90729",
                    "90730",
                    "90731",
                    "90732",
                    "90733",
                    "90734",
                    "90735",
                    "90736",
                    "90737",
                    "90738",
                    "90739",
                    "90740",
                    "90741",
                ],
            },
            {
                name: "Semporna",
                postcode: ["91300", "91307", "91308", "91309"],
            },
            {
                name: "Sipitang",
                postcode: ["89850", "89857", "89858", "89859"],
            },
            {
                name: "Tambunan",
                postcode: ["89650", "89657", "89658", "89659"],
            },
            {
                name: "Tamparuli",
                postcode: ["89250", "89257", "89258", "89259"],
            },
            {
                name: "Tanjung Aru",
                postcode: ["88858"],
            },
            {
                name: "Tawau",
                postcode: [
                    "91000",
                    "91007",
                    "91008",
                    "91009",
                    "91010",
                    "91011",
                    "91012",
                    "91013",
                    "91014",
                    "91015",
                    "91016",
                    "91017",
                    "91018",
                    "91019",
                    "91020",
                    "91021",
                    "91022",
                    "91023",
                    "91024",
                    "91025",
                    "91026",
                    "91027",
                    "91028",
                    "91029",
                    "91030",
                    "91031",
                    "91032",
                    "91033",
                    "91034",
                    "91035",
                    "91045",
                    "91056",
                ],
            },
            {
                name: "Tenom",
                postcode: ["89900", "89907", "89908", "89909"],
            },
            {
                name: "Tuaran",
                postcode: ["89200", "89207", "89208", "89209"],
            },
        ],
    },
    {
        name: "Sarawak",
        city: [
            {
                name: "Asajaya",
                postcode: ["94600"],
            },
            {
                name: "Balingian",
                postcode: ["96350"],
            },
            {
                name: "Baram",
                postcode: ["98050", "98057", "98058", "98059"],
            },
            {
                name: "Bau",
                postcode: ["94000", "94007", "94009"],
            },
            {
                name: "Bekenu",
                postcode: ["98150", "98157", "98159"],
            },
            {
                name: "Belaga",
                postcode: ["96900", "96950", "96999"],
            },
            {
                name: "Belawai",
                postcode: ["96150"],
            },
            {
                name: "Betong",
                postcode: ["95700", "95707", "95709"],
            },
            {
                name: "Bintangor",
                postcode: ["96500", "96507", "96508", "96509", "96510"],
            },
            {
                name: "Bintulu",
                postcode: [
                    "97000",
                    "97004",
                    "97007",
                    "97008",
                    "97009",
                    "97010",
                    "97011",
                    "97012",
                    "97013",
                    "97014",
                    "97015",
                    "97300",
                ],
            },
            {
                name: "Dalat",
                postcode: ["96300", "96307", "96309"],
            },
            {
                name: "Daro",
                postcode: ["96200"],
            },
            {
                name: "Debak",
                postcode: ["95500"],
            },
            {
                name: "Engkilili",
                postcode: ["95800"],
            },
            {
                name: "Julau",
                postcode: ["96600"],
            },
            {
                name: "Kabong",
                postcode: ["94650"],
            },
            {
                name: "Kanowit",
                postcode: ["96700", "96707", "96709"],
            },
            {
                name: "Kapit",
                postcode: ["96800", "96807", "96809"],
            },
            {
                name: "Kota Samarahan",
                postcode: ["94300"],
            },
            {
                name: "Kuching",
                postcode: [
                    "93000",
                    "93010",
                    "93030",
                    "93050",
                    "93100",
                    "93150",
                    "93200",
                    "93250",
                    "93300",
                    "93350",
                    "93400",
                    "93450",
                    "93500",
                    "93502",
                    "93503",
                    "93504",
                    "93505",
                    "93506",
                    "93507",
                    "93508",
                    "93514",
                    "93516",
                    "93517",
                    "93518",
                    "93519",
                    "93520",
                    "93527",
                    "93529",
                    "93532",
                    "93540",
                    "93550",
                    "93551",
                    "93552",
                    "93554",
                    "93556",
                    "93558",
                    "93560",
                    "93564",
                    "93566",
                    "93570",
                    "93572",
                    "93576",
                    "93578",
                    "93582",
                    "93586",
                    "93590",
                    "93592",
                    "93594",
                    "93596",
                    "93600",
                    "93604",
                    "93606",
                    "93608",
                    "93609",
                    "93610",
                    "93612",
                    "93614",
                    "93618",
                    "93619",
                    "93620",
                    "93626",
                    "93628",
                    "93632",
                    "93634",
                    "93648",
                    "93658",
                    "93660",
                    "93661",
                    "93662",
                    "93668",
                    "93670",
                    "93672",
                    "93677",
                    "93690",
                    "93694",
                    "93700",
                    "93702",
                    "93704",
                    "93706",
                    "93708",
                    "93710",
                    "93712",
                    "93714",
                    "93716",
                    "93718",
                    "93720",
                    "93722",
                    "93724",
                    "93726",
                    "93728",
                    "93730",
                    "93732",
                    "93734",
                    "93736",
                    "93738",
                    "93740",
                    "93742",
                    "93744",
                    "93746",
                    "93748",
                    "93750",
                    "93752",
                    "93754",
                    "93756",
                    "93758",
                    "93760",
                    "93762",
                    "93764",
                    "93900",
                    "93902",
                    "93904",
                    "93906",
                    "93908",
                    "93910",
                    "93912",
                    "93914",
                    "93916",
                    "93990",
                ],
            },
            {
                name: "Lawas",
                postcode: ["98850", "98857", "98859"],
            },
            {
                name: "Limbang",
                postcode: ["98700", "98707", "98708", "98709"],
            },
            {
                name: "Lingga",
                postcode: ["94900"],
            },
            {
                name: "Long Lama",
                postcode: ["98300"],
            },
            {
                name: "Lubok Antu",
                postcode: ["95900"],
            },
            {
                name: "Lundu",
                postcode: ["94500", "94507", "94509"],
            },
            {
                name: "Lutong",
                postcode: ["98100", "98107", "98109"],
            },
            {
                name: "Matu",
                postcode: ["96250"],
            },
            {
                name: "Miri",
                postcode: ["98000", "98007", "98008", "98009"],
            },
            {
                name: "Mukah",
                postcode: ["96400", "96410"],
            },
            {
                name: "Nanga Medamit",
                postcode: ["98750"],
            },
            {
                name: "Niah",
                postcode: ["98200"],
            },
            {
                name: "Pusa",
                postcode: ["94950"],
            },
            {
                name: "Roban",
                postcode: ["95300"],
            },
            {
                name: "Saratok",
                postcode: ["95400", "95407", "95409"],
            },
            {
                name: "Sarikei",
                postcode: ["96100", "96107", "96108", "96109"],
            },
            {
                name: "Sebauh",
                postcode: ["97100"],
            },
            {
                name: "Sebuyau",
                postcode: ["94850"],
            },
            {
                name: "Serian",
                postcode: ["94700", "94707", "94709", "94750", "94760"],
            },
            {
                name: "Sibu",
                postcode: ["96000", "96007", "96008", "96009"],
            },
            {
                name: "Siburan",
                postcode: ["94200"],
            },
            {
                name: "Simunjan",
                postcode: ["94800", "94807", "94809"],
            },
            {
                name: "Song",
                postcode: ["96850"],
            },
            {
                name: "Spaoh",
                postcode: ["95600"],
            },
            {
                name: "Sri Aman",
                postcode: ["95000", "95007", "95008", "95009"],
            },
            {
                name: "Sundar",
                postcode: ["98800"],
            },
            {
                name: "Tatau",
                postcode: ["97200"],
            },
        ],
    },
    {
        name: "Selangor",
        city: [
            {
                name: "Ampang",
                postcode: ["68000"],
            },
            {
                name: "Bandar Baru Bangi",
                postcode: ["43650"],
            },
            {
                name: "Bandar Puncak Alam",
                postcode: ["42300"],
            },
            {
                name: "Bangi",
                postcode: ["43600"],
            },
            {
                name: "Banting",
                postcode: ["42700"],
            },
            {
                name: "Batang Berjuntai",
                postcode: ["45600", "45607", "45609", "45620", "45630"],
            },
            {
                name: "Batang Kali",
                postcode: ["44300", "44309", "44330"],
            },
            {
                name: "Batu Caves",
                postcode: ["68100"],
            },
            {
                name: "Beranang",
                postcode: ["43700", "43701"],
            },
            {
                name: "Bukit Rotan",
                postcode: ["45700"],
            },
            {
                name: "Cheras",
                postcode: ["43200", "43207"],
            },
            {
                name: "Cyberjaya",
                postcode: ["63000", "63100", "63200", "63300"],
            },
            {
                name: "Dengkil",
                postcode: ["43800", "43807"],
            },
            {
                name: "Hulu Langat",
                postcode: ["43100"],
            },
            {
                name: "Jenjarom",
                postcode: ["42600", "42610", "42619"],
            },
            {
                name: "Jeram",
                postcode: ["45800"],
            },
            {
                name: "Kajang",
                postcode: ["43000", "43007", "43009", "43558"],
            },
            {
                name: "Kapar",
                postcode: ["42200", "42209"],
            },
            {
                name: "Kerling",
                postcode: ["44100"],
            },
            {
                name: "Klang",
                postcode: [
                    "41000",
                    "41050",
                    "41070",
                    "41100",
                    "41150",
                    "41160",
                    "41200",
                    "41205",
                    "41250",
                    "41299",
                    "41300",
                    "41301",
                    "41400",
                    "41500",
                    "41506",
                    "41560",
                    "41586",
                    "41600",
                    "41670",
                    "41672",
                    "41700",
                    "41710",
                    "41720",
                    "41730",
                    "41750",
                    "41780",
                    "41900",
                    "41902",
                    "41904",
                    "41906",
                    "41908",
                    "41910",
                    "41912",
                    "41914",
                    "41916",
                    "41918",
                    "41990",
                    "42100",
                    "42150",
                ],
            },
            {
                name: "Klia",
                postcode: ["64000"],
            },
            {
                name: "Kuala Kubu Baru",
                postcode: ["44000", "44010", "44020", "44110"],
            },
            {
                name: "Kuala Selangor",
                postcode: ["45000", "45010"],
            },
            {
                name: "Pelabuhan Klang",
                postcode: ["42000", "42007", "42008", "42009"],
            },
            {
                name: "Petaling Jaya",
                postcode: [
                    "46000",
                    "46040",
                    "46050",
                    "46080",
                    "46100",
                    "46150",
                    "46160",
                    "46200",
                    "46300",
                    "46350",
                    "46400",
                    "46460",
                    "46500",
                    "46505",
                    "46506",
                    "46510",
                    "46547",
                    "46549",
                    "46551",
                    "46564",
                    "46582",
                    "46598",
                    "46662",
                    "46667",
                    "46668",
                    "46672",
                    "46675",
                    "46692",
                    "46700",
                    "46710",
                    "46720",
                    "46730",
                    "46740",
                    "46750",
                    "46760",
                    "46770",
                    "46780",
                    "46781",
                    "46782",
                    "46783",
                    "46784",
                    "46785",
                    "46786",
                    "46787",
                    "46788",
                    "46789",
                    "46790",
                    "46791",
                    "46792",
                    "46793",
                    "46794",
                    "46795",
                    "46796",
                    "46797",
                    "46798",
                    "46799",
                    "46800",
                    "46801",
                    "46802",
                    "46803",
                    "46804",
                    "46805",
                    "46806",
                    "46860",
                    "46870",
                    "46960",
                    "46962",
                    "46964",
                    "46966",
                    "46968",
                    "46970",
                    "46972",
                    "46974",
                    "46976",
                    "46978",
                    "46990",
                    "47300",
                    "47301",
                    "47304",
                    "47307",
                    "47308",
                    "47309",
                    "47310",
                    "47400",
                    "47410",
                    "47430",
                    "47800",
                    "47801",
                    "47810",
                    "47820",
                    "47829",
                    "47830",
                    "47850",
                ],
            },
            {
                name: "Puchong",
                postcode: [
                    "47100",
                    "47110",
                    "47120",
                    "47130",
                    "47140",
                    "47150",
                    "47160",
                    "47170",
                    "47180",
                    "47190",
                ],
            },
            {
                name: "Pulau Carey",
                postcode: ["42960", "42999"],
            },
            {
                name: "Pulau Indah",
                postcode: ["42900", "42907", "42920"],
            },
            {
                name: "Pulau Ketam",
                postcode: ["42940"],
            },
            {
                name: "Rasa",
                postcode: ["44200"],
            },
            {
                name: "Rawang",
                postcode: [
                    "48000",
                    "48009",
                    "48010",
                    "48020",
                    "48050",
                    "48100",
                    "48300",
                    "48302",
                ],
            },
            {
                name: "Sabak Bernam",
                postcode: ["45200", "45207", "45209"],
            },
            {
                name: "Sekinchan",
                postcode: ["45400"],
            },
            {
                name: "Semenyih",
                postcode: ["43500"],
            },
            {
                name: "Sepang",
                postcode: ["43900"],
            },
            {
                name: "Serdang",
                postcode: ["43400"],
            },
            {
                name: "Serendah",
                postcode: ["48200", "48250"],
            },
            {
                name: "Seri Kembangan",
                postcode: ["43300", "43399"],
            },
            {
                name: "Shah Alam",
                postcode: [
                    "40000",
                    "40100",
                    "40140",
                    "40150",
                    "40160",
                    "40169",
                    "40170",
                    "40179",
                    "40200",
                    "40250",
                    "40300",
                    "40350",
                    "40400",
                    "40409",
                    "40410",
                    "40440",
                    "40450",
                    "40460",
                    "40470",
                    "40490",
                    "40500",
                    "40502",
                    "40503",
                    "40505",
                    "40512",
                    "40517",
                    "40520",
                    "40529",
                    "40542",
                    "40548",
                    "40550",
                    "40551",
                    "40560",
                    "40564",
                    "40570",
                    "40572",
                    "40576",
                    "40578",
                    "40582",
                    "40590",
                    "40592",
                    "40594",
                    "40596",
                    "40598",
                    "40600",
                    "40604",
                    "40607",
                    "40608",
                    "40610",
                    "40612",
                    "40620",
                    "40622",
                    "40626",
                    "40630",
                    "40632",
                    "40646",
                    "40648",
                    "40650",
                    "40660",
                    "40664",
                    "40670",
                    "40672",
                    "40673",
                    "40674",
                    "40675",
                    "40676",
                    "40680",
                    "40690",
                    "40700",
                    "40702",
                    "40704",
                    "40706",
                    "40708",
                    "40710",
                    "40712",
                    "40714",
                    "40716",
                    "40718",
                    "40720",
                    "40722",
                    "40724",
                    "40726",
                    "40728",
                    "40730",
                    "40732",
                    "40800",
                    "40802",
                    "40804",
                    "40806",
                    "40808",
                    "40810",
                    "40900",
                    "40912",
                    "40915",
                    "40990",
                    "42450",
                ],
            },
            {
                name: "Subang Airport",
                postcode: ["47200", "47210"],
            },
            {
                name: "Subang Jaya",
                postcode: [
                    "47500",
                    "47507",
                    "47510",
                    "47600",
                    "47601",
                    "47610",
                    "47618",
                    "47620",
                    "47630",
                    "47640",
                    "47650",
                    "55555",
                ],
            },
            {
                name: "Sungai Ayer Tawar",
                postcode: ["45100"],
            },
            {
                name: "Sungai Besar",
                postcode: ["45300"],
            },
            {
                name: "Sungai Buloh",
                postcode: ["47000", "47020", "47031"],
            },
            {
                name: "Sungai Pelek",
                postcode: ["43950", "43999"],
            },
            {
                name: "Tanjong Karang",
                postcode: ["45500"],
            },
            {
                name: "Tanjong Sepat",
                postcode: ["42800"],
            },
            {
                name: "Telok Panglima Garang",
                postcode: ["42425", "42500", "42507", "42509"],
            },
        ],
    },
    {
        name: "Terengganu",
        city: [
            {
                name: "Ajil",
                postcode: ["21800", "21810", "21820"],
            },
            {
                name: "Al Muktatfi Billah Shah",
                postcode: ["23400"],
            },
            {
                name: "Ayer Puteh",
                postcode: ["24050"],
            },
            {
                name: "Bukit Besi",
                postcode: ["23200"],
            },
            {
                name: "Bukit Payong",
                postcode: ["21400"],
            },
            {
                name: "Ceneh",
                postcode: ["24060"],
            },
            {
                name: "Chalok",
                postcode: ["21450"],
            },
            {
                name: "Cukai",
                postcode: [
                    "24000",
                    "24007",
                    "24009",
                    "24010",
                    "24020",
                    "24030",
                    "24040",
                ],
            },
            {
                name: "Dungun",
                postcode: ["23000", "23007", "23009", "23050"],
            },
            {
                name: "Jerteh",
                postcode: ["22000", "22010", "22020", "22030", "22040"],
            },
            {
                name: "Kampung Raja",
                postcode: ["22200"],
            },
            {
                name: "Kemasek",
                postcode: ["24200", "24207", "24209", "24210", "24220"],
            },
            {
                name: "Kerteh",
                postcode: ["24300"],
            },
            {
                name: "Ketengah Jaya",
                postcode: ["23300", "23345"],
            },
            {
                name: "Kijal",
                postcode: ["24100", "24107", "24109"],
            },
            {
                name: "Kuala Berang",
                postcode: ["21700"],
            },
            {
                name: "Kuala Besut",
                postcode: ["22300", "22307", "22309"],
            },
            {
                name: "Kuala Terengganu",
                postcode: [
                    "20000",
                    "20050",
                    "20080",
                    "20100",
                    "20200",
                    "20300",
                    "20400",
                    "20500",
                    "20502",
                    "20503",
                    "20504",
                    "20505",
                    "20506",
                    "20508",
                    "20512",
                    "20514",
                    "20516",
                    "20517",
                    "20518",
                    "20519",
                    "20520",
                    "20532",
                    "20534",
                    "20536",
                    "20538",
                    "20540",
                    "20542",
                    "20546",
                    "20548",
                    "20550",
                    "20551",
                    "20552",
                    "20554",
                    "20556",
                    "20560",
                    "20564",
                    "20566",
                    "20568",
                    "20570",
                    "20572",
                    "20576",
                    "20578",
                    "20582",
                    "20586",
                    "20590",
                    "20592",
                    "20596",
                    "20600",
                    "20604",
                    "20606",
                    "20608",
                    "20609",
                    "20610",
                    "20612",
                    "20614",
                    "20618",
                    "20620",
                    "20622",
                    "20626",
                    "20628",
                    "20630",
                    "20632",
                    "20646",
                    "20648",
                    "20656",
                    "20658",
                    "20660",
                    "20661",
                    "20662",
                    "20664",
                    "20668",
                    "20670",
                    "20672",
                    "20673",
                    "20674",
                    "20676",
                    "20680",
                    "20690",
                    "20698",
                    "20700",
                    "20710",
                    "20720",
                    "20900",
                    "20902",
                    "20904",
                    "20906",
                    "20908",
                    "20910",
                    "20912",
                    "20914",
                    "20916",
                    "20918",
                    "20920",
                    "20922",
                    "20924",
                    "20926",
                    "20928",
                    "20930",
                    "20990",
                    "20999",
                    "21000",
                    "21009",
                    "21010",
                    "21020",
                    "21030",
                    "21040",
                    "21050",
                    "21060",
                    "21070",
                    "21080",
                    "21090",
                    "21100",
                    "21109",
                    "21200",
                    "21209",
                    "21210",
                    "21220",
                    "21300",
                    "21309",
                ],
            },
            {
                name: "Marang",
                postcode: ["21600", "21610"],
            },
            {
                name: "Paka",
                postcode: ["23100"],
            },
            {
                name: "Permaisuri",
                postcode: ["22100", "22107", "22109", "22110", "22120"],
            },
            {
                name: "Sungai Tong",
                postcode: ["21500"],
            },
        ],
    },
    {
        name: "W.P Labuan",
        city: [
            {
                name: "Labuan",
                postcode: [
                    "87000",
                    "87007",
                    "87008",
                    "87010",
                    "87011",
                    "87012",
                    "87013",
                    "87014",
                    "87015",
                    "87016",
                    "87017",
                    "87018",
                    "87019",
                    "87020",
                    "87021",
                    "87022",
                    "87023",
                    "87024",
                    "87025",
                    "87026",
                    "87027",
                    "87028",
                    "87029",
                    "87030",
                    "87031",
                    "87032",
                    "87033",
                ],
            },
        ],
    },
    {
        name: "W.P Putrajaya",
        city: [
            {
                name: "Putrajaya",
                postcode: [
                    "62000",
                    "62007",
                    "62050",
                    "62052",
                    "62100",
                    "62150",
                    "62200",
                    "62250",
                    "62300",
                    "62500",
                    "62502",
                    "62503",
                    "62504",
                    "62505",
                    "62506",
                    "62510",
                    "62512",
                    "62514",
                    "62516",
                    "62517",
                    "62518",
                    "62519",
                    "62520",
                    "62522",
                    "62524",
                    "62526",
                    "62527",
                    "62529",
                    "62530",
                    "62532",
                    "62536",
                    "62539",
                    "62540",
                    "62542",
                    "62546",
                    "62550",
                    "62551",
                    "62570",
                    "62574",
                    "62576",
                    "62578",
                    "62582",
                    "62584",
                    "62590",
                    "62592",
                    "62594",
                    "62596",
                    "62602",
                    "62604",
                    "62605",
                    "62606",
                    "62616",
                    "62618",
                    "62620",
                    "62623",
                    "62624",
                    "62628",
                    "62630",
                    "62632",
                    "62648",
                    "62652",
                    "62654",
                    "62657",
                    "62662",
                    "62668",
                    "62670",
                    "62674",
                    "62675",
                    "62676",
                    "62677",
                    "62686",
                    "62690",
                    "62692",
                    "62988",
                ],
            },
        ],
    },
];

export default postcodesToState;
