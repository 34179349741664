import firebase_client from "firebase/app";
import "firebase/storage";

import "firebase/auth";

let firebase_app: firebase_client.app.App | null = null;

if (!firebase_client.apps.length) {
    firebase_app = firebase_client.initializeApp({
        apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
        authDomain: `${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}.firebaseapp.com`,
        databaseURL: `https://${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}.firebaseio.com`,
        projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
        storageBucket: `${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}.appspot.com`,
        messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_SENDER_ID,
        appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    });
} else {
    firebase_app = firebase_client.app();
}

export { firebase_client as firebase };

export const storage = firebase_client.storage();

// Add your Firebase credentials
export default firebase_app;
